import '../src/bootstrap.js';
import '../src/cocoon.js';
import '../src/datepicker.js';

import '../images/wusa_logo.png';
import '../images/favicon.png';
import '../images/Seal_BlackYellow.png';
import '../images/clubs-logo.png';
import '../images/clubs_days.png';

import '../stylesheets/application.scss';
import '../stylesheets/club_listing.scss'


$(function() {
  $('#formTabs a').on('click', function (e) {
    e.preventDefault();
    $(this).tab('show');
  });

});

