import flatpickr from 'flatpickr';

document.addEventListener('DOMContentLoaded', function(e) {
  flatpickr('.datepicker', {
  	enableTime: true,
  	dateFormat: 'Y-m-d H:i'
  });

  flatpickr('.eventdate',{
  	dateFormat: 'Y-m-d'
  });

  flatpickr('.eventtime',{
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i"
  });
});

$(function() {
  $('body').on('cocoon:after-insert', function() {
    document.querySelectorAll('.datepicker').flatpickr({
        dateFormat: "Y-m-d H:i",
        enableTime: true
    });
  });
});
